import type { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";
import type { Block } from '@hamstudy/flamp/dist/block';

export type storedBlock = Pick<Block, 'data' | 'keyword' | 'hash' | 'blockNum' | 'controlWord'>;

export interface TransmissionSchema {
  id: string;

  /** Should we use the timestamp to calculate what entries still need to be transmitted */
  hasAllChanges?: boolean;
  hash: string;
  entryCount?: number;
  eventId: string;
  blocks: {
    [key: number]: storedBlock;
    PROG?: storedBlock;
    ID?: storedBlock;
    FILE?: storedBlock;
    DESC?: storedBlock;
    SIZE?: storedBlock;
    EOF?: storedBlock;
    EOT?: storedBlock;
  };
  timestamp: Date;
}

export class TransmissionSentModel extends idbModel<TransmissionSchema> {
  static readonly StoreName = 'transmissionSent';
  static readonly StoreSchema = {
    key: TransmissionSentModel.StoreName,
    value: null as any as TransmissionSchema,
    indexes: {
      'eventId': 'eventId',
      'stationId': 'stationId',
      'event-hash': ['eventId','hash'],
      'event-timestamp': ['eventId','timestamp'],
    },
    keyPath: 'hash',
    indexOptions: {
      'event-hash': { unique: true },
    },
  };
  // If hash is used in more than one event it should be shown in each event

  static async findByHash(hash: string, eventId?: string): Promise<TransmissionSchema | undefined> {
    const store = await this.getObjectStore<'transmissionSent'>("readonly");
    const range = eventId ? IDBKeyRange.only([eventId, hash]) : IDBKeyRange.only(hash);
    return await store.index('event-hash').get(range);
  }

  static async getAllForEvent(eventId: string): Promise<TransmissionSchema[]> {
    const store = await this.getObjectStore<'transmissionSent'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: TransmissionSchema[] = [];

    const iterate = store.index('eventId').iterate(range);
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }

  static async getLastestSentTimestamp(eventId: string): Promise<Date> {
    const store = await this.getObjectStore<'transmissionSent'>("readonly");

    const range = IDBKeyRange.bound([eventId, new Date(0)], [eventId, new Date()]);

    const iterate = store.index('event-timestamp').iterate(range, 'prev');

    for await (const doc of iterate) {
      if (doc.value.hasAllChanges) {
        return new Date(doc.value.timestamp);
      }
    }
    return new Date(0);
  }

  static async deleteByHash(hash: string, eventId: string): Promise<void> {
    const store = await this.getObjectStore<'transmissionSent'>("readwrite");
    const range = IDBKeyRange.only([eventId, hash]);
    for await (const r of store.index('event-hash').iterate(range)) {
      await r.delete!();
    }
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'transmissionSent'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
