export const dbName = <const>'events';

// tslint:disable max-classes-per-file
abstract class EventDoc {
  id!: string;
  name!: string;
  startDate?: Date;
  endDate?: Date;
  slug?: string;
}
export type IEventDoc = EventDoc;
export class Event extends EventDoc {
  static async getEvent(idOrSlug: string): Promise<Event | null> { return null; }
  static async getEvents(): Promise<Event[]> { return []; }
  static async delete(event: Event): Promise<void> { return; }

  static async importEvent(eventData: Partial<IEventDoc>, fallbackName: string) {
    const event = (await this.getEvent(eventData.id || eventData.slug || '')) || new this({id: eventData.id, name: '', slug: eventData.slug});
    event.name = eventData.name || event.name || fallbackName;
    event.startDate = eventData.startDate && new Date(eventData.startDate) || event.startDate;
    event.endDate = eventData.endDate && new Date(eventData.endDate) || event.endDate;
    event.slug = eventData.slug;
    try {
      await event.save();
    } catch (e) {
      console.error(e); // tslint:disable-line no-console
      throw e;
    }
    return event;
  }

  constructor(
    obj: Partial<Event> & Pick<Event, 'name'>,
  ) {
    super();
    Object.assign(this, obj);
  }
  async save(): Promise<void> { return; }
}
export default Event;
