import 'material-icons/iconfont/material-icons.css';

import Vue from 'vue';
import { } from 'vuetify'; // This is so typescript can find 'vuetify/lib'
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import scssVariables from '@/variables.scss';

Vue.use(Vuetify);
Vue.directive('ripple', Ripple);

export default new Vuetify({
  icons: {
    // iconfont: 'mdi',
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        hamstudyBlue: {
          base: scssVariables.hamstudyBlue,
          darken1: scssVariables.primary,
        },
        hamstudyYellow: {
          base: scssVariables.accent,
        },
        primary: scssVariables.primary,
        accent: scssVariables.accent,
        error: scssVariables.error,
        info: scssVariables.info,
        success: scssVariables.success,
        warning: scssVariables.warning,

        background: '#F4F4F4',
        surface: '#FFF',
      },
      dark: {
        hamstudyBlue: {
          base: scssVariables.hamstudyBlue,
          darken1: scssVariables.primary,
        },
        hamstudyYellow: {
          base: scssVariables.accent,
        },
        primary: scssVariables.primary,
        accent: '#e0a600', // scssVariables.accent,
        error: scssVariables.error,
        info: scssVariables.info,
        success: scssVariables.success,
        warning: scssVariables.warning,

        background: '#001e21',
        surface: '#003136',
      },
    },
  },
});
