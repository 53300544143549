
import { openDB } from '@/lib/idb';
import type { IDBPDatabase, IDBPTransaction, DBSchema } from '@/lib/idb';

type DBSchemaValue = DBSchema[string];

export interface DBSchemaValue2 extends DBSchemaValue {
  keyPath?: string;
  indexOptions?: Partial<Record<string, IDBIndexParameters>>;
}
export interface DBSchema2 {
  [s: string]: DBSchemaValue2;
}

export class idbHost { // tslint:disable-line class-name
  private setDb!: (db: IDBPDatabase) => any;
  private setError!: (err: Error) => any;
  #isReady = new Promise<IDBPDatabase>((resolve, reject) => {
    this.setDb = resolve;
    this.setError = reject;
  });
  private db!: IDBPDatabase;

  private schema: DBSchema2 = {};

  private get isReady() { return this.#isReady; }

  constructor(private dbName: string, private version: number) {
  }

  async onUpgrade(db: IDBPDatabase, oldVer: number, newVer: number | null, transaction: IDBPTransaction<unknown, string[], 'versionchange'>): Promise<any> {
    const {schema} = this || {};
    // Called the first time the db is opened
    for (const k of Object.keys(schema)) {
      const cur = schema[k];
      const storeName = cur.key;
      if (!db.objectStoreNames.contains(String(storeName))) {
        const store = db.createObjectStore(storeName as string, {keyPath: cur.keyPath || 'id'});
        for (const idxName of Object.keys(cur.indexes || {})) {
          const idx = cur.indexes![idxName];
          const opts = cur.indexOptions?.[idxName];
          console.log('creating index', cur, idxName, idx, opts); // tslint:disable-line no-console
          store.createIndex(idxName, idx as string[], opts);
        }
      } else {
        const store = transaction.objectStore(String(storeName));
        for (const idxName of Object.keys(cur.indexes || {})) {
          const idx = cur.indexes![idxName];
          const opts = cur.indexOptions?.[idxName];
          if (!store.indexNames.contains(idxName)) {
            console.log('creating index', cur, idxName, idx, opts); // tslint:disable-line no-console
            store.createIndex(idxName, idx as string[], opts);
          }
        }
      }
    }
  }

  onUnexpectedTerminate() {
    /** TODO: decide what to do in this case */
  }

  openDb<Schema extends DBSchema2 = Record<string, never>>(): Promise<IDBPDatabase<Schema>>;
  openDb<Schema extends DBSchema2>(s: Schema): Promise<IDBPDatabase<Schema>>; // tslint:disable-line unified-signatures
  async openDb<Schema extends DBSchema2 = any>(s?: Schema): Promise<IDBPDatabase> {
    if (s) {
      this.schema = {
        ...this.schema,
        ...s,
      };
    }
    const dbReady = openDB(this.dbName, this.version, {
        upgrade: (db, oldVer, newVer, transaction) => this.onUpgrade(db, oldVer, newVer, transaction),
        terminated: () => this.onUnexpectedTerminate(),
    });

    dbReady.then(async newDb => {
      this.db = newDb;
      this.setDb(newDb); // resolve the promise

      return newDb;
    }).catch(this.setError);

    return await dbReady;
  }

  registerModel(name: string, params: DBSchema[string]) {
    this.schema[name] = params;
  }
}
