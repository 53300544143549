import Vue from 'vue';
import { formatDate, normalizeTimeStr } from '@/lib/formatDate';

Vue.filter('time', (value: number | Date | string, withSeconds = false) => {
  return normalizeTimeStr(value, withSeconds);
});

Vue.filter('date', (date: Date, format?: string) => {
  return formatDate(date, format || 'MM/DD HH:mm');
});

Vue.filter('day', (date: Date) => {
  return formatDate(date, 'DD');
});
