/* use require() instead of import so it only actually imports the code that is used */
import EventBase, { type IEventDoc } from './base/event';

let Event!: typeof EventBase;
if (__USE_INDEXEDDB__) {
  Event = require('./indexeddb/event').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Event = require('./ajax/event').default; // tslint:disable-line no-var-requires
}
type Event = EventBase;

export type {
  IEventDoc,
};
export {
  Event,
};
export default Event;

if (isDev) {
  (window as any).Event = Event;
}
