import { getCurrentInstance } from 'vue';
import { type TOptions, type TFunction } from 'i18next';

export function useTranslate() {
  const curInstance = getCurrentInstance();
  if (!curInstance) {
    console.error('useTranslate must be called in setup function'); // tslint:disable-line no-console
    return () => '';
  }
  function $t<TInterpolationMap extends object = Record<string, any>>(key: string | string[], options?: TOptions<TInterpolationMap> | string): ReturnType<TFunction> {
    return (curInstance!.proxy as any).$t(key, options);
  }
  return $t;
}
