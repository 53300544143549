import { resolve } from 'path';

export enum AUDIOSOURCE_TYPES {
  DEFAULT = 0,
  MIC = 1,
  CAMCORDER = 5,
  VOICE_RECOGNITION = 6,
  VOICE_COMMUNICATION = 7,
  UNPROCESSED = 9,
}

interface AudioSourceType {
  DEFAULT: AUDIOSOURCE_TYPES.DEFAULT;
  MIC: AUDIOSOURCE_TYPES.MIC;
  CAMCORDER: AUDIOSOURCE_TYPES.CAMCORDER;
  VOICE_RECOGNITION: AUDIOSOURCE_TYPES.VOICE_RECOGNITION;
  VOICE_COMMUNICATION: AUDIOSOURCE_TYPES.VOICE_COMMUNICATION;
  UNPROCESSED: AUDIOSOURCE_TYPES.UNPROCESSED;
}

interface SAMPLERATE_ENUM { // tslint:disable-line
  CD_AUDIO_44100Hz: number;
  CD_HALF_22050Hz: number;
  CD_QUARTER_11025Hz: number;
  CD_XA_37800Hz: number;
  MINI_DV_32000Hz: number;
  NTSC_44056Hz: number;
  TELEPHONE_8000Hz: number;
  VOIP_16000Hz: number;
}

export interface AudioInputPluginConfig {
  sampleRate: number;
  bufferSize: number;
  channels: number;
  format: 'PCM_16BIT' | 'PCM_8BIT';
  normalize?: boolean;
  normalizationFactor?: number;
  streamToWebAudio: boolean;
  audioContext?: AudioContext;

  concatenateMaxChunks?: number;

  audioSourceType?: AUDIOSOURCE_TYPES;
}
export interface AudioInputPlugin {
  AUDIOSOURCE_TYPE: AudioSourceType;
  SAMPLERATE: SAMPLERATE_ENUM;
  FORMAT: {
    PCM_16BIT: 'PCM_16BIT';
    PCM_8BIT: 'PCM_8BIT';
  };

  initialize( captureCfg: AudioInputPluginConfig, onInitialized: () => void): void;
  checkMicrophonePermission( onComplete: (hasPerm: boolean) => void ): void;
  getMicrophonePermission( onComplete: (hasPerm: boolean) => void ): void;

  start( captureCfg?: Partial<AudioInputPluginConfig> ): void;
  stop( onStopped?: () => void ): void;

  getCfg(): AudioInputPluginConfig;

  connect( audioNode: AudioNode ): void;
  disconnect(): void;

  getAudioContext(): AudioContext;
}

declare global {
  const audioinput: AudioInputPlugin;
}

export function checkMicrophonePermission(): Promise<boolean> {
  return new Promise<boolean>((res, rej) => {
    audioinput.checkMicrophonePermission(hasPerm => res(hasPerm));
  });
}
export function getMicrophonePermission(): Promise<boolean> {
  return new Promise<boolean>((res, rej) => {
    audioinput.getMicrophonePermission(hasPerm => hasPerm ? res(true) : rej(new Error("Could not access microphone")));
  });
}
