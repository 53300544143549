import Base, { type IStationDoc } from '../base/station';
import ObjectId from '@/data/models/ObjectId';
import { EntryModel, StationModel } from '@/data/models';

export class Station extends Base {
  // This is used to verify that the station id we have belongs to this event, if not it'll be null
  static async getStation(obj: {eventId: string; stationId?: string;}): Promise<Station | null>; // tslint:disable-line unified-signatures
  static async getStation(obj: {eventId: string; raceId?: string; stationNumber: number}): Promise<Station | null>; // tslint:disable-line unified-signatures
  static async getStation({eventId, raceId, stationId, stationNumber}: Partial<{eventId: string; raceId: string; stationId: string; stationNumber: number;}>): Promise<Station | null> {
    if (!(eventId || stationId)) { return null; }
    let doc: IStationDoc | undefined;
    if (!eventId && stationId) {
      doc = await StationModel.findById(stationId);
    } else if (raceId && typeof stationNumber === 'number') {
      doc = await StationModel.getByStationNumberForRace(raceId, stationNumber);
    } else if (eventId && typeof stationNumber === 'number') {
      doc = await StationModel.getByStationNumberForEvent(eventId, stationNumber);
    } else if (eventId && stationId) {
      doc = await StationModel.findById(stationId, eventId);
    }
    return doc ? dbConverter.fromDB(doc) : null;
  }

  static async getStations(eventId: string): Promise<Station[]> {
    const docs = await StationModel.getAllStationsForEvent(eventId);
    return docs.map((doc) => dbConverter.fromDB(doc));
  }

  static async delete(station: Station) {
    await Promise.all([
      EntryModel.deleteAllForStation(station.id),
      StationModel.deleteById(station.id),
    ]);
  }

  async save() {
    await StationModel.saveData(dbConverter.toDB(this));
  }
}
export default Station;

// DB data converter
const dbConverter = {
  toDB(x: Station) {
    x.id = x.id || new ObjectId().toString();
    return {
      id: x.id,
      eventId: x.eventId,
      name: x.name,
      distance: x.distance,
      raceId: x.raceId,
      stationNumber: x.stationNumber,
      stationNumberDisplayed: x.stationNumberDisplayed,
    };
  },
  fromDB(doc: IStationDoc) {
    const raceEventId = String(doc.eventId);
    return new Station(raceEventId, Object.assign({}, doc));
  },
};
