import { concat, uniq } from 'lodash';

export const locales: {[namespace: string]: any} = {};
export const requireContext = combineContexts(
  require.context('.', false, /^\.\/.*\.(ts|json)$/),
  require.context('json-loader!yaml-loader!.', false, /^\.\/.*\.(yaml)$/),
);

for (const filename of requireContext.keys()) {
  if (filename === './index.ts') { continue; }
  const ns = filename.replace(/^\.\//, '').replace(/\.\w+$/, '');
  locales[ns] = requireContext(filename);
}


function combineContexts(...contexts: __WebpackModuleApi.RequireContext[]): __WebpackModuleApi.RequireContext {
  const webpackContext: __WebpackModuleApi.RequireContext = ((req: string) => {
    // Find the first match and execute
    const context = contexts
      .map((ctx) => ctx.keys().indexOf(req) >= 0 && ctx || (void 0))
      .find((a) => !!a)
    ;
    return context && context(req);
  }) as any;
  webpackContext.keys = () => {
    return uniq<string>(
      concat<string>([], ...contexts.map((context) => context.keys())),
    );
  };

  return webpackContext;
}

