
import type { NotUndefined } from '@/lib/utils';
import type { IDBPDatabase, IDBPObjectStore } from '@/lib/idb';
import type {DBType, StoreNames, StoreType} from './index';

async function getDb() {
  const { getDb } = await import('./index'); // tslint:disable-line:no-shadowed-variable
  return getDb();
}


type IDBTransactionOptions = NotUndefined<Parameters<IDBPDatabase['transaction']>[2]>['durability'];

export class idbModel<Schema extends {id: any} = {id: string}> { // tslint:disable-line:class-name
    static StoreName: StoreNames = '' as any;

    protected getDb() { return getDb(); }
    protected static getDb() { return getDb(); }
    protected static async getLocalTransaction(mode: IDBTransactionMode, durability: IDBTransactionOptions = 'relaxed') {
      const db = await getDb();
      const txn = db.transaction([this.StoreName], mode, {
        durability,
      });
      return txn;
    }
    protected static async getObjectStore<T extends StoreNames>(mode: IDBTransactionMode, durability: IDBTransactionOptions = 'relaxed') {
      const txn = await this.getLocalTransaction(mode, durability);
      return txn.objectStore(this.StoreName as T) as IDBPObjectStore<DBType, StoreNames[], T, IDBTransactionMode>;
    }
    // ['constructor']: idbModel<{id: string}>;

    constructor(public obj: Schema) {
    }

    static async findById<T extends StoreType<StoreNames> = StoreType<StoreNames>>(id: string): Promise<T | undefined> {
      const txn = await this.getLocalTransaction('readonly');
      const obj = await txn.objectStore(this.StoreName).get(id);

      return obj as T;
    }
    static async saveData<T extends Record<string, any>>(obj: T) {
      const txn = await this.getLocalTransaction('readwrite', 'strict');
      return await txn.objectStore(this.StoreName).put!(obj as any);
    }

  /**
   * Used strictly for unit tests
   */
  static async countAll() {
    const store = await this.getObjectStore('readonly');
    return await store.count();
  }

  static async deleteById(id: string) {
    const store = await this.getObjectStore('readwrite', 'strict');
    return await store.delete!(id);
  }

  static async deleteAll() {
    const store = await this.getObjectStore('readwrite', 'strict');

    const operations: Array<Promise<any>> = [];
    for await (const r of store.iterate()) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
