import { defineStore, type storeToRefs } from 'pinia';
import { computed, nextTick, ref, watch } from 'vue';
import { useDark } from '@vueuse/core';

export enum FILTER_RUNNERS_TO {
  ALL = 'all',
  EXPECTING = 'expecting',
  IN_STATION = 'inStation',
  NEEDS_TRANSMISSION = 'needsTransmission',
  SEEN = 'seen',
  TRANSMITTED = 'transmitted',
}
type NUMPAD = 'keypad' | 'dialpad';

export const useSettingsStore = defineStore('settings', () => {
  const darkMode = useDark();
  const state = {
    authentication: ref(''),
    callsign: ref(''),
    preferDarkMode: ref<boolean | 'auto'>('auto'),
    serverUri: ref(''),
    useDialpadArrangement: ref('keypad' as NUMPAD),
    POWER_SAVING: ref(true),
    recordsDataTables: ref([{
      listFilter: FILTER_RUNNERS_TO.SEEN,
      sortBy: ['modified'] as [string],
      sortDesc: [true] as [boolean],
    }]),
    skipTimeIn: ref(false),
    skipTimeOut: ref(false),
    includeSecondsWithTime: ref(false),
  };

  migrateFromVuex(state as any);

  return {
    ...state,
    darkMode: computed(() => {
      return typeof state.preferDarkMode.value === 'boolean' ? state.preferDarkMode.value : darkMode.value;
    }),
    canEdit: computed(() => {
      return !__USE_AJAX__ || !!state.authentication.value;
    }),
    addRecordsDataTable(payload?: { listFilter?: FILTER_RUNNERS_TO, sortBy?: [string], sortDesc: [boolean] }) {
      state.recordsDataTables.value.push({
        listFilter: payload?.listFilter || FILTER_RUNNERS_TO.SEEN,
        sortBy: payload?.sortBy || ['modified'],
        sortDesc: payload?.sortDesc ?? [true],
      });
    },
    removeRecordsDataTable() {
      if (state.recordsDataTables.value.length > 1) {
        state.recordsDataTables.value.pop();
      }
    },
    updateRecordsDataTableListFilter(payload: { dataTableIndex: number, listFilter: FILTER_RUNNERS_TO }) {
      state.recordsDataTables.value[payload.dataTableIndex].listFilter = payload.listFilter;
    },
    updateRecordsDataTableSortBy(payload: { dataTableIndex: number, sortBy: [string] }) {
      state.recordsDataTables.value[payload.dataTableIndex].sortBy = payload.sortBy;
    },
    updateRecordsDataTableSortDescending(payload: { dataTableIndex: number, sortDesc: [boolean] }) {
      state.recordsDataTables.value[payload.dataTableIndex].sortDesc = payload.sortDesc;
    },
    async login(accessToken: string) {
      if (__USE_AJAX__ || __USE_INDEXEDDB__) {
        const axios = (require('@/models/ajax/config') as typeof import('@/models/ajax/config')).axios;
        const resp = await axios.post('/api/user/login', {accessToken});
        state.authentication.value = `Bearer ${resp.data}`;
      }
    },
  };
}, {
  persist: true,
});

async function migrateFromVuex(state: ReturnType<typeof storeToRefs<ReturnType<typeof useSettingsStore>>>) {
  // Migration from vuex
  if (!('Settings' in localStorage)) {
    return;
  }

  await nextTick();

  interface VuexState {
    authentication: string;
    callsign: string;
    DARK_MODE: boolean;
    serverUri: string;
    useDialpadArrangement: 'keypad' | 'dialpad';
    POWER_SAVING: boolean;
    recordsDataTables: Array<{
      listFilter: FILTER_RUNNERS_TO;
      sortBy: [string];
      sortDesc: [boolean];
    }>;
    skipTimeIn: boolean;
    skipTimeOut: boolean;
    includeSecondsWithTime: boolean;
  }

  const vuexState: VuexState = JSON.parse(localStorage.getItem('Settings') || '{}').settings;
  if (!vuexState) {
    return;
  }

  let preferDarkMode: boolean | 'auto' = 'auto';
  if (localStorage.getItem('dark') === 'true') {
    preferDarkMode = true;
  } else if (localStorage.getItem('dark') === 'false') {
    preferDarkMode = false;
  }

  state.authentication.value = vuexState.authentication;
  state.callsign.value = vuexState.callsign;
  state.preferDarkMode.value = preferDarkMode;
  state.serverUri.value = vuexState.serverUri;
  state.useDialpadArrangement.value = vuexState.useDialpadArrangement;
  state.POWER_SAVING.value = vuexState.POWER_SAVING;
  state.recordsDataTables.value = vuexState.recordsDataTables;
  state.skipTimeIn.value = vuexState.skipTimeIn;
  state.skipTimeOut.value = vuexState.skipTimeOut;
  state.includeSecondsWithTime.value = vuexState.includeSecondsWithTime;

  localStorage.removeItem('Settings');
}
