export const dbName = <const>'stations';

export type ImportStation = Partial<IStationDoc> & { stationNumber: number; };
// tslint:disable max-classes-per-file
abstract class StationDoc {
  id!: string;
  eventId!: string;
  name?: string;
  stationNumber!: number;
  stationNumberDisplayed!: string;
  distance?: number;
  raceId?: string;
}
export type IStationDoc = StationDoc;
export class Station extends StationDoc {
  // This is used to verify that the station id we have belongs to this event, if not it'll be null
  static async getStation(obj: {eventId: string; stationId?: string}): Promise<Station | null>; // tslint:disable-line unified-signatures
  static async getStation(obj: {eventId: string; stationNumber: number}): Promise<Station | null>; // tslint:disable-line unified-signatures
  static async getStation(obj: Partial<{eventId: string; stationId: string; stationNumber: number}>): Promise<Station | null> { throw new Error("Can't use base model"); }
  static async getStations(eventId: string, raceId?: string | undefined): Promise<Station[]> { throw new Error("Can't use base model"); }
  static async delete(station: Station): Promise<void> { throw new Error("Can't use base model"); }

  static async importStation(eventId: string, stationData: ImportStation) {
    const station = (await this.getStation({eventId, stationNumber: stationData.stationNumber})) || new this(eventId, {stationNumber: stationData.stationNumber});
    station.name = stationData.name || station.name || '';
    if ('distance' in stationData) { station.distance = parseFloat(String(stationData.distance)); }
    if ('stationNumberDisplayed' in stationData) { station.stationNumberDisplayed = stationData.stationNumberDisplayed || ''; }
    if (!station.id && !!stationData.id) { station.id = stationData.id; }
    try {
      await station.save();
    } catch (e) {
      console.error(e); // tslint:disable-line no-console
      throw e;
    }
    return station;
  }

  constructor(
    eventId: string,
    obj: Partial<Station> & Pick<Station, 'stationNumber'>,
  ) {
    super();
    Object.assign(this, obj, {eventId});
  }
  async save(): Promise<void> { throw new Error("Can't use base model"); }
}
export default Station;
