/* use require() instead of import so it only actually imports the code that is used */
import StationBase, { type IStationDoc } from './base/station';

let Station!: typeof StationBase;
if (__USE_INDEXEDDB__) {
  Station = require('./indexeddb/station').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Station = require('./ajax/station').default; // tslint:disable-line no-var-requires
}
type Station = StationBase;

export type {
  IStationDoc,
};
export {
  Station,
};
export default Station;

if (isDev) {
  (window as any).Station = Station;
}
