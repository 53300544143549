import Base, { IRaceDoc } from '../base/race';
import ObjectId from '@/data/models/ObjectId';
import { RaceModel, StationModel } from '@/data/models';
import { type StationSchema } from '@/data/models/station';
import { type IStationDoc } from '../base/station';


export class Race extends Base {
  static async getRace({id, eventId, name}: {eventId: string; id?: string; name?: string}): Promise<Race | null> {
    if (!id && !name) { return null; }
    let doc: IRaceDoc | undefined;
    if (id) {
      doc = await RaceModel.findById(id);
    } else if (name) {
      doc = await RaceModel.getByEventAndName(eventId, name);
    }
    if (doc) {
      await populateStations(eventId, [doc]);
    }
    return doc ? dbConverter.fromDB(doc) : null;
  }
  static async getRaces(eventId: string): Promise<Race[]> {
    const docs = await RaceModel.getForEvent(eventId);
    await populateStations(eventId, docs);
    return docs.map(dbConverter.fromDB);
  }
  static async delete(race: Race): Promise<void> {
    await RaceModel.deleteById(race.id);
  }

  async save() {
    await RaceModel.saveData(dbConverter.toDB(this));
  }
}
export default Race;

// DB data converter
const dbConverter = {
  toDB(x: Race) {
    x.id = x.id || (new ObjectId()).toString();
    return x;
  },
  fromDB(doc: IRaceDoc) {
    const eventId = String(doc.eventId);
    return new Race(eventId, Object.assign({}, doc));
  },
};

async function populateStations(eventId: string, races: IRaceDoc[]) {
  const allStations = await StationModel.getAllStationsForEvent(eventId);
  const allStationsMap = allStations.reduce((map, s) => {
    map[s.id] = s;
    return map;
  }, {} as {[id: string]: StationSchema});
  for (const race of races) {
    (race.stationsPopulated as IStationDoc[]) = race.stations.map((station) => {
      const originalStation = allStationsMap[station.id];
      return {
        ...originalStation,
        ...station,
      };
    });
  }
}
