import Axios, { type AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '@/stores/settings';
import { watch } from 'vue';

export const axios = Axios.create();

axios.interceptors.response.use((res) => res, err => {
  const message = err.response?.data?.message || err?.message || err;
  if ((err as AxiosError).response?.status !== 409) {
    console.error(message); // tslint:disable-line no-console
  }
  if ((err as AxiosError).response?.status === 403) {
    removeAuthenticationFromStore();
  }
  throw err;
});

function removeAuthenticationFromStore() {
  useSettingsStore().authentication = '';
}

const {
  authentication,
  serverUri,
} = storeToRefs(useSettingsStore());

watch(serverUri, (newVal) => {
  if (newVal) {
    axios.defaults.baseURL = newVal;
  }
}, { immediate: true });

watch(authentication, (newVal) => {
  if (newVal) {
    axios.defaults.headers.authentication = newVal;
    axios.get('/api/user');
  } else {
    delete axios.defaults.headers.authentication;
  }
}, { immediate: true });

if (isDev) {
  (window as any).axios = axios;
}
