import { defineStore } from 'pinia';
import { ref } from 'vue';
import Event from '@/models/event';
import Station from '@/models/station';

export const useRaceEventStore = defineStore('raceEvent', () => {
  const state = {
    eventId: ref(''),
    eventSlug: ref(''),
    station: ref<Station | null>(null),
    stationId: ref(''),
    async setRaceEvent(event: Event | null) {
      state.eventId.value = event?.id || '';
      state.eventSlug.value = event?.slug || '';
      if (!event) {
        state.stationId.value = '';
        state.station.value = null;
      } else {
        let station = await Station.getStation({stationId: state.stationId.value, eventId: event.id});
        state.stationId.value = station?.id || '';
        state.station.value = station;
      }
    },
  };
  return state;
}, {
  persist: {
    paths: ['eventId', 'eventSlug', 'stationId'],
  },
});
