// tslint:disable:no-var-imports
// tslint:disable:no-var-requires

import axios from 'axios';

import {
  setFileLocation,
  initialize,
  MT63Client,
  wasmModule,
} from '@hamstudy/mt63-wasm';

import wasmFile from "@hamstudy/mt63-wasm/dist/mt63Wasm.wasm";

const wasmBinary = axios.get<ArrayBuffer>(wasmFile, {responseType: 'arraybuffer'}).then(result => {
  const dlBuff = result.data;
  const dlArr = new Uint8Array(dlBuff);
  // const shBuff = new SharedArrayBuffer(dlBuff.byteLength);
  // const shArr = new Uint8Array(shBuff);
  // shArr.set(dlArr);
  return dlArr;
}).catch(err => {
  alert("Could not load MT63 library.");
  // tslint:disable-next-line
  console.warn("Failed to load mt64 wasm file:", err);
});

setFileLocation("mt63Wasm.wasm", wasmFile);
export const readyDfd = wasmBinary.then(shArr =>
  initialize(mod => {
    mod.wasmBinary = shArr;
    return mod;
  }),
);

export {MT63Client, wasmModule, wasmBinary};
