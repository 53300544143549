import { Amp } from '@hamstudy/flamp';
import type { File } from '@hamstudy/flamp/dist/deamp';
import { Block } from '@hamstudy/flamp/dist/block';
import { MT63Client } from '@/lib/mt63';

export const txDbName = <const>'transmissionTX';
export const rxDbName = <const>'transmissionRX';

type storedBlock = Pick<Block, 'data' | 'keyword' | 'hash' | 'blockNum' | 'controlWord'>;

// tslint:disable max-classes-per-file
abstract class TransmissionDoc {
  /** Should we use the timestamp to calculate what entries still need to be transmitted */
  hasAllChanges?: boolean;
  hash!: string;
  entryCount?: number;
  eventId!: string;
  blocks!: {
    [key: number]: storedBlock;
    PROG?: storedBlock;
    ID?: storedBlock;
    FILE?: storedBlock;
    DESC?: storedBlock;
    SIZE?: storedBlock;
    EOF?: storedBlock;
    EOT?: storedBlock;
  };
  timestamp!: Date;
}
export type ITransmissionDoc = TransmissionDoc;
export class Transmission extends TransmissionDoc {
  static activeTransmission: Transmission | null = null;
  static fromCallsign = '';
  static fromAmp(eventId: string, amp: Amp, txOrRx: 'tx' | 'rx'): Transmission { throw new Error("Can't use base model 'Transmission.fromAmp'"); }
  static fromString(filename: string, inputBuffer: string, eventId: string, fromCallsign?: string): Transmission { throw new Error("Can't use base model 'Transmission.fromString'"); } // tslint:disable-line only-arrow-functions

  static async getLastEntryTXTimestamp(eventId: string): Promise<Date> { throw new Error("Can't use base model 'getLastEntryTXTimestamp'"); }
  static async getTxDoc(hash: string, eventId?: string): Promise<Transmission | null> { throw new Error("Can't use base model 'getTXDoc'"); }
  static async getTxDocs(eventId: string): Promise<Transmission[]> { throw new Error("Can't use base model 'getTXDocs'"); }
  static async getRxDoc(hash: string, eventId?: string): Promise<Transmission | null> { throw new Error("Can't use base model 'getRXDoc'"); }
  static async getRxDocs(eventId: string): Promise<Transmission[]> { throw new Error("Can't use base model 'getRXDocs'"); }
  static async delete(x: Transmission): Promise<void> { return; }

  static async saveTransmission(file: File, eventId: string) {
    const rx = (await this.getRxDoc(file.hash, eventId)) || new this(eventId, {txOrRx: 'rx', hash: file.hash, blocks: {}});
    const blocks: Amp['blocks'] = {};
    for (const block of file.headerBlocks) {
      rx.blocks[block.keyword as any] = block;
      blocks[block.keyword as any] = block;
    }
    for (const key of Object.keys(file.dataBlock)) {
      const block = file.dataBlock[key as any];
      if (!block) { continue; } // Not possible, but makes typescript happy
      rx.blocks[key as any] = block;
      blocks[block.keyword as any] = block;
    }
    for (const key of (Object.keys(rx.blocks))) {
      const block = rx.blocks[key as any];
      if (!(key in blocks)) {
        file.addBlock(block);
        blocks[key as any] = block;
      }
    }
    await rx.save();
  }

  blocks: Amp['blocks'] = {}; // Intentionally settings it to type Block and not storedBlock gets converted when pulled from db
  timestamp: Date;
  txOrRx!: 'tx' | 'rx';

  transmitting = false;
  private audioSource?: AudioBufferSourceNode;

  constructor(
    eventId: string,
    obj: Partial<Transmission> & Pick<Transmission, 'blocks' | 'hash' | 'txOrRx'>,
  ) {
    super();
    Object.assign(this, obj, {eventId});
    this.timestamp = new Date();
  }

  async transmit(blocksToTransmit?: {[key: number]: boolean, headers: boolean}, fromCallsign?: string, toCallsign?: string) {
    if (Transmission.activeTransmission && this !== Transmission.activeTransmission) {
      // console.log('Starting a new transmission during an active transmission');
      Transmission.activeTransmission.stop();
    }
    if (this.transmitting && this.audioSource) {
      // console.log('Restarting transmission during transmitting');
      this.audioSource.addEventListener('ended', () => this.transmit(blocksToTransmit, fromCallsign, toCallsign));
      this.stop();
      return;
    }
    const audioCtx: AudioContext = new ((window as any).AudioContext || (window as any).webkitAudioContext)();
    const mtClient = new MT63Client();
    const blocks: Amp['blocks'] = blocksToTransmit ? {} : this.blocks;
    if (blocksToTransmit) {
      for (const key of Object.keys(this.blocks) as Array<keyof Amp['blocks']>) {
        const block = this.blocks[key] as Block;
        const isDataBlock = !isNaN(Number(key));
        if (
          (!isDataBlock && blocksToTransmit?.headers)
          || (isDataBlock && blocksToTransmit?.[Number(key)])
        ) {
          blocks[key] = block;
        }
      }
    }
    const transmissionStr = Amp.toString(blocks, fromCallsign, toCallsign);
    if (!transmissionStr) { console.log('Nothing to transmit'); return; } // tslint:disable-line no-console

    this.audioSource = mtClient.encodeString(transmissionStr, 2000, 1, audioCtx).source;
    this.audioSource.addEventListener('ended', () => {
      this.transmitting = false;
      if (Transmission.activeTransmission === this) {
        Transmission.activeTransmission = null;
      }
      audioCtx.close();
    });
    this.audioSource.start();
    this.transmitting = true;
    Transmission.activeTransmission = this;
  }

  stop() {
    if (this.audioSource) {
      try {
        this.audioSource.stop();
      } catch (e) {
        this.transmitting = false;
      }
    }
    // this.transmitting = false; // This is send via event handler
  }

  async save(): Promise<void> { throw new Error("Can't use base model 'transmission save'"); }
}
export default Transmission;
