/* use require() instead of import so it only actually imports the code that is used */
import EntryBase, { type IEntryDoc } from './base/entry';

let Entry!: typeof EntryBase;
if (__USE_INDEXEDDB__) {
  Entry = require('./indexeddb/entry').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Entry = require('./ajax/entry').default; // tslint:disable-line no-var-requires
}
type Entry = EntryBase;

export type { IEntryDoc };
export {
  Entry,
};
export default Entry;

if (isDev) {
  (window as any).Entry = Entry;
}
