import type { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";

export interface StationSchema {
  id: string;
  eventId: string;
  name?: string;
  stationNumber: number;
  stationNumberDisplayed: string;
  distance?: number;
  raceId?: string;
}

export class StationModel extends idbModel<StationSchema> {
  static readonly StoreName = 'stations';
  static readonly StoreSchema = {
    key: StationModel.StoreName,
    value: null as any as StationSchema,
    indexes: {
      'eventId': 'eventId',
      'event-stationNum': ['eventId', 'stationNumber'],
      'race-stationNum': ['raceId', 'stationNumber'],
    },
    keyPath: 'id',
    indexOptions: {
      'event-stationNum': { unique: true },
    },
  };

  static async findById<T extends StoreType<StoreNames> = StationSchema>(id: string, eventId?: string): Promise<T | undefined> {
    const doc = await super.findById<T>(id);
    if (eventId && doc && 'eventId' in doc && (doc as any).eventId !== eventId) {
      return undefined;
    }
    return doc;
  }

  static async getByStationNumberForRace(raceId: string, stationNumber: number): Promise<StationSchema | undefined> {
    const store = await this.getObjectStore<'stations'>("readonly");
    const range = IDBKeyRange.only([raceId, stationNumber]);

    return await store.index('race-stationNum').get(range);
  }

  static async getByStationNumberForEvent(eventId: string, stationNumber: number): Promise<StationSchema | undefined> {
    const store = await this.getObjectStore<'stations'>("readonly");
    const range = IDBKeyRange.only([eventId, stationNumber]);

    return await store.index('event-stationNum').get(range);
  }

  static async getAllStationsForEvent(eventId: string): Promise<StationSchema[]> {
    const store = await this.getObjectStore<'stations'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: StationSchema[] = [];

    const iterate = store.index('eventId').iterate(range);
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'stations'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
