import I18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import resources from '.';
import { formatDate } from '@/lib/formatDate';

export const defaultNS = 'app';

export const i18next = I18next
  .createInstance({
    defaultNS,
    fallbackLng: 'en',
    fallbackNS: 'common',
    interpolation: {
      format: (value: any, format, lng) => {
          if (format === 'uppercase') { return value.toUpperCase(); }
          if (value instanceof Date) { return formatDate(value, format); }
          return value;
      },
    },
    parseMissingKeyHandler(key: string) { return process.env.NODE_ENV === 'production' ? '' : key; },
    resources,
  })
  .use(LngDetector)
;
