import type { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";

export interface EntrySchema {
  id: string;
  eventId: string;
  participantId: string;
  stationId: string;

  modified: Date;
  timeIn: Date | null;
  timeOut: Date | null;
}

export class EntryModel extends idbModel<EntrySchema> {
  static readonly StoreName = 'entrys';
  static readonly StoreSchema = {
    key: EntryModel.StoreName,
    value: null as any as EntrySchema,
    indexes: {
      'eventId': 'eventId',
      'participantId': 'participantId',
      'stationId': 'stationId',
      'stationId-participantId': ['stationId', 'participantId'],
      'stationId-eventId': ['stationId', 'eventId'],
      'stationId-modified': ['stationId', 'modified'],
    },
    keyPath: 'id',
    indexOptions: {
      'stationId-participantId': { unique: true },
    },
  };

  static findById<T extends StoreType<StoreNames> = EntrySchema>(id: string): Promise<T | undefined> {
    return super.findById<T>(id);
  }

  static async getEntrysForEvent(eventId: string): Promise<EntrySchema[]> {
    const store = await this.getObjectStore<'entrys'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: EntrySchema[] = [];

    const iterate = store.index('eventId').iterate(range, 'prev');
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }

  static async getEntrysForStation(stationId: string, sinceDate?: Date): Promise<EntrySchema[]> {
    const store = await this.getObjectStore<'entrys'>("readonly");

    const range = sinceDate ? IDBKeyRange.bound([stationId, sinceDate], [stationId, new Date()]) : IDBKeyRange.only(stationId);

    const outDocs: EntrySchema[] = [];

    const iterate = store.index(sinceDate ? 'stationId-modified' : 'stationId').iterate(range, 'prev');
    for await (const doc of iterate) {
      if (sinceDate && doc.value.modified < sinceDate) {
        continue;
      }
      outDocs.push(doc.value);
    }
    return outDocs;
  }

  static async getEntrysForParticipant(participantId: string): Promise<EntrySchema[]> {
    const store = await this.getObjectStore<'entrys'>("readonly");

    const range = IDBKeyRange.only(participantId);

    const outDocs: EntrySchema[] = [];

    const iterate = store.index('participantId').iterate(range, 'prev');
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }


  static async findByStationAndParticipant(stationId: string, participantId: string): Promise<EntrySchema | undefined> {
    const store = await this.getObjectStore<'entrys'>("readonly");

    const range = IDBKeyRange.only([stationId, participantId]);

    return await store.index('stationId-participantId').get(range);
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'entrys'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }

  static async deleteAllForStation(stationId: string): Promise<void> {
    const store = await this.getObjectStore<'entrys'>('readwrite', 'strict');

    const range = IDBKeyRange.only(stationId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('stationId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }

  static async deleteAllForParticipant(participantId: string): Promise<void> {
    const store = await this.getObjectStore<'entrys'>('readwrite', 'strict');

    const range = IDBKeyRange.only(participantId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('participantId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
