import Vue from 'vue';
import { createPinia, setActivePinia, PiniaVuePlugin } from 'pinia';
export { defineStore } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
setActivePinia(pinia);
pinia.use(piniaPluginPersistedstate);

if (isDev) {
  (window as any).pinia = pinia;
}
