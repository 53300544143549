import { defineStore } from "pinia";

export interface SNACKBAR {
  action?: {
    text: string;
    callback: () => void;
  };
  i18next?: string;
  message?: string;
  timeout?: number;

  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  top?: boolean;
}

export const useNotificationStore = defineStore('notifications', () => {
  const state = {
    snackbar: null as SNACKBAR | null,
  };
  return state;
});
