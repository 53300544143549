import { defineStore } from 'pinia';
import { ref } from 'vue';
import Transmission from '@/models/transmission';
import { useRaceEventStore } from '@/stores/raceEvent';

export const useTransmissionStore = defineStore('transmission', () => {
  const raceEventStore = useRaceEventStore();
  const state = {
    history: ref<Transmission[]>([]),
    transmission: ref<Transmission | null>(null),

    async updateHistoryList() {
      const eventId = raceEventStore.eventId;
      const history = await Transmission.getTxDocs(eventId);
      history.sort((x,y) => x.timestamp > y.timestamp ? -1 : 1);
      state.history.value = history;
    },
  };
  return state;
});
