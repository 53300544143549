import { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";

export interface ParticipantSchema {
  id: string;
  age?: string;
  bibNumber: number;
  firstName?: string;
  lastName?: string;
  modified?: Date;
  note?: string;
  home?: string;
  eventId: string;
  raceId?: string;
  sex?: 'M' | 'F';
  team?: string;
  dnfReason: string;
  dnfStation: number | null;
}

export class ParticipantModel extends idbModel<ParticipantSchema> {
  static readonly StoreName = 'participants';
  static readonly StoreSchema = {
    key: ParticipantModel.StoreName,
    value: null as any as ParticipantSchema,
    indexes: {
      'eventId': 'eventId',
      'event-bibNum': ['eventId', 'bibNumber'],
    },
    keyPath: 'id',
    indexOptions: {
      'event-bibNum': <IDBIndexParameters>{ unique: true },
    },
  };

  static async findById<T extends StoreType<StoreNames> = ParticipantSchema>(id: string): Promise<T | undefined> {
    return await super.findById<T>(id);
  }

  static async getParticipantsForEvent(eventId: string, ids?: string[]): Promise<ParticipantSchema[]> {
    const store = await this.getObjectStore<'participants'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: ParticipantSchema[] = [];

    const iterate = store.index('eventId').iterate(range);
    for await (const doc of iterate) {
      if (!ids || ids.includes(doc.value.id)) {
        outDocs.push(doc.value);
      }
    }
    return outDocs;
  }

  static async getParticipantByBibNumber(eventId: string, bibNumber: number): Promise<ParticipantSchema | undefined> {
    const store = await this.getObjectStore<'participants'>("readonly");

    const range = IDBKeyRange.only([eventId, bibNumber]);

    return await store.index('event-bibNum').get(range);
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'participants'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }

}
