// This is a browser-implementation which uses the strongest
// crypto that is available

function toHexString(num: number) { // only valid for a single byte
  let str = num.toString(16);
  if (str.length < 2) {
    return `0${str}`;
  } else {
    return str;
  }
}
function arrayToNum(bytes: Uint8Array) {
  return parseInt(Array.from(bytes).reduce((memo, i) => memo + toHexString(i), ''), 16);
}

function randomMathRandom(max: number, bytes: number = 2) {
  return Math.floor(Math.random() * max);
}

function randomWithCrypto(max: number, bytes: number = 2) {
  let arr = new Uint8Array(bytes);
  let n = -1;
  window.crypto.getRandomValues(arr);
  let maxNum = Math.pow(2, bytes * 8); // e.g. 1 byte = 256, 2 bytes = 65536, etc
  if (maxNum < max) {
    // tslint:disable-next-line no-console
    console.warn(`Insufficient resolution for this number! max ${max} but max possible is ${maxNum}`);
  }
  // console.log("Max num: ", maxNum, arrayToNum(arr), max);
  n = Math.floor(arrayToNum(arr) / maxNum * max);
  return n;
}

const win: any = typeof window === 'undefined' ? {} : window;
const supportsCrypto = !!win.Uint8Array && !!win.crypto && !!win.crypto.getRandomValues;

export const random = supportsCrypto ? randomWithCrypto : randomMathRandom;
