import Base, { IParticipantDoc} from '../base/participant';
import ObjectId from '@/data/models/ObjectId';
import { EntryModel, ParticipantModel } from '@/data/models';

export class Participant extends Base {
  static async getParticipant(eventId: string, bibNumber: number) {
    if (typeof bibNumber !== 'number') { throw new Error(`getParticipant expects bibNumber to be a number, but it was of type ${typeof bibNumber}`); }
    const doc = await ParticipantModel.getParticipantByBibNumber(eventId, bibNumber);
    return doc ? dbConverter.fromDB(doc) : null;
  }

  static async getParticipants(eventId: string, ids?: string[]) {
    const docs = await ParticipantModel.getParticipantsForEvent(eventId, ids);
    return docs.map((doc) => dbConverter.fromDB(doc));
  }

  static async delete(participant: Participant) {
    await Promise.all([
      EntryModel.deleteAllForParticipant(participant.id),
      ParticipantModel.deleteById(participant.id),
    ]);
  }

  async save() {
    await ParticipantModel.saveData(dbConverter.toDB(this));
  }
}
export default Participant;

// DB data converter
const dbConverter = {
  toDB(x: Participant) {
    x.id = x.id || new ObjectId().toString();
    x.dnfReason = x.dnfReason || '';
    x.dnfStation = x.dnfStation ?? null;
    return x;
  },
  fromDB(doc: IParticipantDoc) {
    const raceEventId = String(doc.eventId);
    return new Participant(raceEventId, Object.assign({}, doc));
  },
};
