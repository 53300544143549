import type { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";
import ObjectId from './ObjectId';

export interface EventSchema {
  id: string;
  name: string;
  startDate?: Date;
  endDate?: Date;
  slug?: string;
}

export class EventModel extends idbModel<EventSchema> {
  static readonly StoreName = 'events';
  static readonly StoreSchema = {
    key: EventModel.StoreName,
    value: null as any as EventSchema,
    indexes: {
      'slug': ['slug'],
    },
    keyPath: 'id',
    indexOptions: {
      'slug': { unique: true },
    },
  };

  static findById<T extends StoreType<StoreNames> = EventSchema>(id: string) {
    return super.findById<T>(id);
  }

  static async findByIdOrSlug(idOrSlug: string): Promise<EventSchema | undefined> {
    const store = await this.getObjectStore<'events'>('readonly');
    const byId = store.get(idOrSlug);
    const bySlug = store.index('slug').get([idOrSlug]);

    const [findById, findBySlug] = await Promise.all([byId, bySlug]);

    return findById || findBySlug;
  }

  static async listEvents(): Promise<EventSchema[]> {
    const store = await this.getObjectStore<'events'>("readonly");

    const outDocs: EventSchema[] = [];

    const iterate = store.iterate();
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }

}

export async function createEvent(opts: Partial<EventSchema> & { name: string }): Promise<EventSchema>;
export async function createEvent(nameOrOpts: string | Partial<EventSchema> & { name: string }, opts: Partial<EventSchema> = {}): Promise<EventSchema> {
  const name = typeof nameOrOpts === 'string' ? nameOrOpts : nameOrOpts.name;
  opts = typeof nameOrOpts === 'string' ? opts : nameOrOpts;
  const newEvent: EventSchema = {
    ...opts,
    id: new ObjectId().toString(),
    name,
  };
  await EventModel.saveData(newEvent);
  return newEvent;
}
