
import { idbHost } from '../lib/idbHost';
import { EntryModel } from './entry';
import { EventModel } from './event';
import { ParticipantModel } from './participant';
import { RaceModel } from './race';
import { StationModel } from './station';
import { TransmissionSentModel } from './transmissionSent';
import { TransmissionReceivedModel } from './transmissionReceived';
import type { UnpromiseReturnType } from '@/lib/utils';
import type { IDBPDatabase } from '@/lib/idb';

export type SchemaFromDb<Db> = Db extends IDBPDatabase<infer U> ? U : Db;
export type DBType = SchemaFromDb<UnpromiseReturnType<typeof getDb>>;

const host = new idbHost('runner-tracker', 3);

const MySchema = <const>{
  [EntryModel.StoreName]: EntryModel.StoreSchema,
  [EventModel.StoreName]: EventModel.StoreSchema,
  [ParticipantModel.StoreName]: ParticipantModel.StoreSchema,
  [RaceModel.StoreName]: RaceModel.StoreSchema,
  [StationModel.StoreName]: StationModel.StoreSchema,
  [TransmissionReceivedModel.StoreName]: TransmissionReceivedModel.StoreSchema,
  [TransmissionSentModel.StoreName]: TransmissionSentModel.StoreSchema,
};
export type StoreNames = keyof typeof MySchema;
export type StoreType<T extends StoreNames> = (typeof MySchema)[T]['value'];

const db = host.openDb(MySchema);

export function getDb() {
  return db;
}

export { EntryModel } from './entry';
export { EventModel } from './event';
export { ParticipantModel } from './participant';
export { RaceModel } from './race';
export { StationModel } from './station';
export { TransmissionReceivedModel } from './transmissionReceived';
export { TransmissionSentModel } from './transmissionSent';
