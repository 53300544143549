import { Resource } from 'i18next';
const resource: Resource = {};
const requireModule = require.context('.', true, /\bindex.ts$/);

for (const filename of requireModule.keys()) {
  if (filename === './index.ts') { continue; }
  const lng = (/\.\/([a-zA-Z\-]+)\/index.ts$/.exec(filename) || [])[1];
  if (!lng) { continue; }
  resource[lng] = requireModule(filename).locales;
}

export default resource;
