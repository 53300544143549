import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import routes, { ROUTES } from './routes';
import { useSettingsStore } from '@/stores/settings';
import { useRaceEventStore } from '@/stores/raceEvent';
export { ROUTES };

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach(async (routeTo, routeFrom, next) => {
  const settingsStore = useSettingsStore();
  if (__USE_AJAX__) {
    // @TODO if not authenticated but going to any of the following then allow
    // event list, participant list, participant details
    // Though make sure that those pages honor the can edit flag
    // settingsStore.canEdit
    if (!settingsStore.authentication) {
      if (routeTo.name === ROUTES.ajaxAuth?.name) {
        return next();
      }
      return next({ name: ROUTES.ajaxAuth?.name });
    }
  }
  // If no raceEvent/Station is selected route to the race event picker
  const {
    eventId,
    eventSlug,
    station,
  } = useRaceEventStore();
  if (!eventId || !station) {
    if (
      routeTo.name === ROUTES.raceEventPicker.name ||
      (routeTo.name === ROUTES.stationPicker.name && eventId)
    ) { return next(); }
    return next({ name: ROUTES.raceEventPicker.name });
  }
  if (routeFrom === VueRouter.START_LOCATION
    && eventId
    && station
    && routeTo.fullPath === '/'
  ) {
    return next({
      name: ROUTES.inputTimes.name,
      params: {
        eventIdOrSlug: eventSlug || eventId,
        stationIdOrSlug: station.id,
      },
    });
  }
  next();
});

export default router;
