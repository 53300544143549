export const dbName = <const>'races';
import { type Station, type IStationDoc } from './station';

export type ImportRace = Partial<IRaceDoc> & { name: string; stations: Array<IRaceDoc['stations'][0] & {stationNumber: number;}>; };

// tslint:disable max-classes-per-file
abstract class RaceDoc {
  id!: string;
  eventId!: string;
  name!: string;
  distance?: number;
  stations: Array<{
    id: string;
  }> = [];
  readonly stationsPopulated?: IStationDoc[];
}
export type IRaceDoc = RaceDoc;
export class Race extends RaceDoc {
  static async getRace(obj: {eventId: string; id?: string; name?: string}): Promise<Race | null> { throw new Error("Can't use base model"); }
  static async getRaces(eventId: string): Promise<Race[]> { throw new Error("Can't use base model"); }
  static async delete(race: Race): Promise<void> { throw new Error("Can't use base model"); }

  static async importRace(eventId: string, raceData: ImportRace, stationMap: {[stationNumber: number]: Station}) {
    const race = (await this.getRace({eventId, name: raceData.name})) || new this(eventId, raceData);
    race.name = raceData.name || race.name;
    if ('distance' in raceData) { race.distance = parseFloat(String(raceData.distance)); }
    if (raceData.distance) {
      race.distance = parseFloat(String(raceData.distance));
    }
    race.stations = [];
    for (const s of raceData.stations) {
      const station = stationMap[s.stationNumber];
      if (station) {
        s.id = station.id;
        race.stations.push(s);
      }
    }
    try {
      await race.save();
    } catch (e) {
      console.error(e); // tslint:disable-line no-console
      throw e;
    }
    return race;
  }

  constructor(
    eventId: string,
    obj: Partial<Race> & Pick<Race, 'name'>,
  ) {
    super();
    Object.assign(this, obj, {eventId});
  }
  async save(): Promise<void> { return; }
}
export default Race;
