/* use require() instead of import so it only actually imports the code that is used */
import RaceBase, { type IRaceDoc } from './base/race';

let Race!: typeof RaceBase;
if (__USE_INDEXEDDB__) {
  Race = require('./indexeddb/race').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Race = require('./ajax/race').default; // tslint:disable-line no-var-requires
}
type Race = RaceBase;

export type {
  IRaceDoc,
};
export {
  Race,
};
export default Race;

if (isDev) {
  (window as any).Race = Race;
}
