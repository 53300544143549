import { wasmBinary as wasmBinaryPromise } from '@/lib/mt63';

export class MT63AudioWorkletNode extends AudioWorkletNode {
  constructor(audioContext: AudioContext, mediaStream: MediaStream) {
    super(audioContext, 'mt63-processor');

    this.port.onmessage = (evt) => {
      if (evt.data.req === 'startup') {
        this.postWasmBinary();
      }
    };

    this.connect(audioContext.destination);
    const sourceNode = audioContext.createMediaStreamSource(mediaStream);
    sourceNode.connect(this);
  }

  private async postWasmBinary() {
    const wasmBinary = await wasmBinaryPromise;
    this.port.postMessage({binary: wasmBinary});
  }
}
