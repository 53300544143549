// tslint:disable
import { RouteConfig } from 'vue-router';
import Records from './views/Records.vue';
import Grid from './views/Grid.vue';
import InputTimes from './views/InputTimes.vue';
import RaceEventPicker from './views/RaceEventPicker.vue';
import RunnerDetails from './views/RunnerDetails.vue';
import Receive from './views/Receive.vue';
import Results from './views/Results.vue';
import StationPicker from './views/StationPicker.vue';
import Transmit from './views/Transmit.vue';
import ViewTransmission from './views/ViewTransmission.vue';

export const ROUTES = {
  ajaxAuth: {
    path: '/authenticate',
    name: 'authenticate',
    component: () => import(/* webpackChunkName: "authenticate" */ './views/Authenticate.vue'),
  },
  inputTimes: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/input-times',
    name: 'inputTimes',
    component: InputTimes,
  },
  runnerDetails: {
    path: '/:eventIdOrSlug/runners/:bibNumber',
    name: 'runnerDetails',
    component: RunnerDetails,
    props: (route) => {
      return { bibNumber: Number(route.params.bibNumber) };
    },
  },
  results: {
    path: '/results',
    name: 'results',
    component: Results,
  },
  entries: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/entries',
    name: 'entries',
    component: Records,
  },
  transmit: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/transmit',
    name: 'transmit',
    component: Transmit,
  },
  receive: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/receive',
    name: 'receive',
    component: Receive,
  },
  viewReceived: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/receive/:hash',
    name: 'viewReceived',
    component: ViewTransmission,
    props: true,
  },
  viewTransmission: {
    path: '/:eventIdOrSlug/:stationIdOrSlug/transmit/:hash',
    name: 'viewTransmission',
    component: ViewTransmission,
    props: true,
  },
  raceEventPicker: {
    path: '/events',
    name: 'raceEventPicker',
    component: RaceEventPicker,
  },
  stationPicker: {
    path: '/:eventIdOrSlug/stations',
    name: 'stationPicker',
    component: StationPicker,
  },
  grid: {
    path: '/:eventIdOrSlug/grid',
    name: 'grid',
    component: Grid,
  },
} satisfies Record<string, RouteConfig>;

export default [
  ...(__USE_AJAX__ || __USE_INDEXEDDB__ ? [ROUTES.ajaxAuth] : []),
  ROUTES.inputTimes,
  ROUTES.runnerDetails,
  ROUTES.entries,
  ROUTES.receive,
  ROUTES.results,
  ROUTES.transmit,
  ROUTES.viewReceived,
  ROUTES.viewTransmission,
  ROUTES.raceEventPicker,
  ROUTES.stationPicker,
  ROUTES.grid,
] satisfies RouteConfig[];
