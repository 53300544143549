import { Entry } from '@/models/entry';
import { Participant } from '@/models/participant';
import { Event, type IEventDoc} from '@/models/event';
import { Race } from '@/models/race';
import { Station } from '@/models/station';
import { useNotificationStore, type SNACKBAR } from '@/stores/notifications';

import { type ImportRace } from '@/models/base/race';
import { type ImportStation } from '@/models/base/station';
import { type ImportParticipant } from '@/models/base/participant';
import { useRaceEventStore } from '@/stores/raceEvent';

const raceEventStore = useRaceEventStore();
const notificationStore = useNotificationStore();

export async function importDrops(body: string) {
  // Body should be a bunch of lines containing
  // bibNumber,droppedStation,droppedReason
  const eventId: string = raceEventStore.eventId;
  const promises: Array<Promise<void>> = [];
  for (const line of body.split('\n')) {
    const [bibNumber, stationNumber, reason] = line.split(',');
    const promise = (async () => {
      const [participant, station] = await Promise.all([
        Participant.getParticipant(eventId, Number(bibNumber)),
        Station.getStation({eventId, stationNumber: Number(stationNumber)}),
      ]);
      if (!participant || !station) { return; }
      participant.drop({reason, station});
      return participant.save();
    })();
    promises.push(promise);
  }
  await Promise.all(promises);
}

export async function parseStationCSV(filename: string, body: string) {
  const promises: Array<Promise<any>> = [];
  const stationNumber = parseInt(filename.split('-')[1], 10);
  // TODO ask for what station this file should be stored
  if (stationNumber !== 0 && !stationNumber || isNaN(stationNumber)) { return alert("Received file for unknown station"); }
  const eventId: string = raceEventStore.eventId;
  let station = await Station.getStation({eventId, stationNumber});
  if (!station) {
    station = new Station(eventId, {stationNumber});
    await station.save();
  }
  const stationId = station.id;
  const lines = body.split('\n');
  let metadata: {[key: string]: any} = {}; // file may have JSON metadata
  try {
    metadata = JSON.parse(lines[0]);
    if (typeof metadata === 'object' && !Array.isArray(metadata)) {
      lines.shift(); // remove the metadata line from the lines array
    }
  } catch (e) {} // tslint:disable-line no-empty
  const epoch = metadata.epoch || 0;
  const includeSecondsWithTime = String(epoch).length === 10;
  for (let [lineIndex, line] of lines.entries()) {
    line = line?.trim();
    if (!line) { continue; } // Don't parse empty lines in the file
    const [bibNum, timeInStr, timeOutStr, dnfReason] = line.split(',');
    const markDidNotFinish = dnfReason !== (void 0);
    if (lineIndex === 0 && isNaN(Number(bibNum))) { continue; } // Skip the first line if it's a header
    if (isNaN(Number(bibNum))) { throw new Error('CSV is corrupt, expected bibnumber to be a number'); }
    if (isNaN(Number(timeInStr))) { throw new Error('CSV is corrupt, expected timeInStr to be a number'); }
    if (isNaN(Number(timeOutStr))) { throw new Error('CSV is corrupt, expected timeOutStr to be a number'); }
    const promise = (async () => {
      let participant = await Participant.getParticipant(eventId, Number(bibNum));
      if (!participant) { participant = new Participant(eventId, {bibNumber: Number(bibNum)}); }
      if (markDidNotFinish) {
        participant.drop({reason: dnfReason, station: station as Station});
      } else if (participant.dnfStation === stationNumber) {
        // Received a transmission for a runner that was previously dropped at said station, but is no longer reporting so
        participant.undrop();
      }
      await participant.save();
      let entry = await Entry.getEntry(stationId, participant.id);
      if (!entry) { entry = new Entry(eventId, participant.id, stationId); }
      if (timeInStr && !entry.timeIn) {
        entry.timeIn = new Date((epoch + Number(timeInStr)) * (includeSecondsWithTime ? 6E2 : 6E4));
      }
      if (timeOutStr && !entry.timeOut) {
        entry.timeOut = new Date((epoch + Number(timeOutStr)) * (includeSecondsWithTime ? 6E2 : 6E4));
      }
      await entry.save();
    })();
    promises.push(promise);
  }
  await Promise.all(promises);
  notificationStore.snackbar = {
    i18next: 'receive.imported',
  } as SNACKBAR;
  return {
    runnerCount: body.split('\n').filter(l => !!l).length,
    stationNumber,
  };
}
export async function parseRaceData(body: string, filename?: string, createNewEvent = true) {
  const promises: Array<Promise<any>> = [];
  if (!body || typeof body !== 'string') { return; }
  // Import event data into current event event or create a new one
  const eventInfo: Partial<Event> & {name: string} = {
    id: !createNewEvent && raceEventStore.eventId || (void 0),
    name: filename || 'Event',
  };
  if (body.startsWith('race,')) {
    // race,name,startDate,endDate,id
    const [x, name, startDate, endDate, id] = body.split('\n')[0].trim().split(','); // tslint:disable-line:variable-name
    if (name) {
      eventInfo.name = name;
    }
    if (startDate && Date.parse(startDate)) {
      eventInfo.startDate = new Date(startDate);
    }
    if (endDate && Date.parse(endDate)) {
      eventInfo.endDate = new Date(endDate);
    }
    if (id) {
      eventInfo.id = id;
    }
  }
  const event = new Event(eventInfo);
  await event.save();
  for (let line of body.split('\n')) {
    line = line.trim();
    // s,stationNumber,name,distance
    if (line.startsWith('s,')) {
      const [x, sn, name, distance] = line.split(',');
      if (isNaN(Number(sn))) {
        throw new Error("Error parsing station number from data");
      }
      const stationNumber = Number(sn);
      const promise = Station.getStation({eventId: event.id, stationNumber}).then(station => {
        if (!station) { station = new Station(event.id, {stationNumber}); }
        station.name = name;
        station.distance = parseFloat(distance) || (void 0);
        return station.save();
      });
      promises.push(promise);
    // r,bibNumber,firstName,lastName,age,sex,note,team,home
    } else if (line.startsWith('r,')) {
      const [x,bibNumber,firstName,lastName,age,sex,note,team,home] = line.split(',');
      if (isNaN(Number(bibNumber))) {
        throw new Error("Error parsing runner data from data"); // tslint:disable-line
      }
      const promise = Participant.getParticipant(event.id, Number(bibNumber)).then(runner => {
        if (!runner) { runner = new Participant(event.id, {bibNumber: Number(bibNumber)}); }
        runner.firstName = firstName || runner.firstName;
        runner.lastName = lastName || runner.lastName;
        runner.age = age || runner.age;
        runner.sex = sex as 'M' | 'F' || runner.sex;
        runner.note = note || runner.note;
        runner.team = team || runner.team;
        runner.home = home || runner.home;
        return runner.save();
      });
      promises.push(promise);
    }
  }
  await Promise.all(promises);
  notificationStore.snackbar = {
    i18next: 'receive.imported',
  } as SNACKBAR;
}

export async function importData(data: IImportData | string, filename: string, eventId?: string) {
  if (!data) { return; }
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data) as IImportData;
    } catch(e) {
      console.error(e); // tslint:disable-line no-console
      return;
    }
  }
  data.id = eventId || data.id;
  const event = await Event.importEvent(data, filename);

  const stationMap: {[stationNumber: string]: Station} = {};
  const stationPromises: Array<Promise<any>> = [];
  for (const sData of data.stations || []) {
    if (typeof sData.stationNumber !== 'number') { continue; }
    const stationPromise = (async () => {
      const s = await Station.importStation(event.id, sData);
      stationMap[s.stationNumber] = s;
    })();
    stationPromises.push(stationPromise);
  }

  await Promise.all(stationPromises);

  const raceMap: {[raceName: string]: Race} = {};
  for (const rData of data.races || []) {
    const racePromise = (async () => {
      const r = await Race.importRace(event.id, rData, stationMap);
      raceMap[r.name] = r;
    })();
    await racePromise;
  }

  const promises: Array<Promise<any>> = [];
  for (const rData of data.participants || data.runners || []) {
    if (!rData.bibNumber) { continue; }
    if (rData.raceName) {
      const race = raceMap[rData.raceName];
      if (race?.id) {
        rData.raceId = race.id;
      }
    }
    const promise = (async () => {
      const participant = await Participant.importParticipant(event.id, rData);
      const ePromises: Array<Promise<any>> = [];
      const entrys = rData.entrys;
      if (entrys) {
        await Promise.all(stationPromises);
        for (const stationNumber of Object.keys(entrys || {})) {
          const station = stationMap[stationNumber];
          if (!station) { continue; }
          const ePromise = (async () => {
            const eData = entrys[stationNumber];
            const entry = await Entry.importEntry(event.id, station.id, participant.id, eData);
          })();
          await ePromise;
          ePromises.push(ePromise);
        }
      }
      await Promise.all(ePromises);
    })();
    await Promise;
    promises.push(promise);
  }

  await Promise.all(promises);
  notificationStore.snackbar = {
    i18next: 'receive.imported',
  } as SNACKBAR;
}

export interface IImportData extends Partial<IEventDoc> {
  races?: ImportRace[];
  runners?: ImportParticipant[];
  participants?: ImportParticipant[];
  stations?: ImportStation[];
}
