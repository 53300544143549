/* use require() instead of import so it only actually imports the code that is used */
import ParticipantBase, { type IParticipantDoc } from './base/participant';

let Participant!: typeof ParticipantBase;
if (__USE_INDEXEDDB__) {
  Participant = require('./indexeddb/participant').default; // tslint:disable-line no-var-requires
} else if (__USE_AJAX__) {
  Participant = require('./ajax/participant').default; // tslint:disable-line no-var-requires
}
type Participant = ParticipantBase;

export type {
  IParticipantDoc,
};
export {
  Participant,
};
export default Participant;

if (isDev) {
  (window as any).Participant = Participant;
}
