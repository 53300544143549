import { i18next } from '@/locales/i18next';

const replacementMap = {
  YYYY: (date: Date) => ('000' + date.getFullYear()).slice(-4),
  YY: (date: Date) => ('000' + date.getFullYear()).slice(-2),
  MMMM: (date: Date) => i18next.t(`months.${date.getMonth()}`),
  MMM: (date: Date) => i18next.t(`months.${date.getMonth()}`, {context: 'short'}),
  MM: (date: Date) => ('0' + (date.getMonth()+1)).slice(-2),
  M: (date: Date) => String(date.getMonth()+1),
  DD: (date: Date) => ('0' + date.getDate()).slice(-2),
  HH: (date: Date) => ('0' + date.getHours()).slice(-2),
  mm: (date: Date) => ('0' + date.getMinutes()).slice(-2),
  ss: (date: Date) => ('0' + date.getSeconds()).slice(-2),
  dddd: (date: Date) => i18next.t(`days.${date.getDay()}`),
  ddd: (date: Date) => i18next.t(`days.${date.getDay()}`, {context: 'short'}),
};

const interpolationMap = [
  <const>['YYYY', '{{1}}'],
  <const>['YY', '{{2}}'],
  <const>['MMMM', '{{3}}'],
  <const>['MMM', '{{4}}'],
  <const>['MM', '{{5}}'],
  <const>['M', '{{6}}'],
  <const>['DD', '{{7}}'],
  <const>['HH', '{{8}}'],
  <const>['mm', '{{9}}'],
  <const>['ss', '{{10}}'],
  <const>['dddd', '{{11}}'],
  <const>['ddd', '{{12}}'],
];

export function formatDate(date: Date, format?: string) {
  if (!format) { return date.toString(); }
  let str = format;
  for (const [key,interpolated] of interpolationMap) {
    str = str.replace(key, interpolated);
  }
  for (const [key,interpolated] of interpolationMap) {
    str = str.replace(interpolated, replacementMap[key](date));
  }
  return str;
}
export default formatDate;

export function normalizeTimeStr(value: number | string | Date | null, withSeconds = false): string {
  if (value instanceof Date && value.getTime() % 6E4 !== 0) {
    // If the time entered contains seconds, we should display seconds
    withSeconds = true;
  }
  if (typeof value === 'string' || typeof value === 'number') {
    value = String(value || '');
    value.replace(':', '');
    while (value.length < 6) {
      value = '0' + value;
    }
    value = value.substring(value.length - (withSeconds ? 6 : 4));
    // At this point we should have a string with four or six numbers eg: "0000" or "000000"
    value = value.substring(0, 2) + ':' + value.substring(2, 4) + (withSeconds ? ':' + value.substring(4, 6) : '');
    return value;
  } else if (value === null || value === (void 0)) {
    return '';
  } else {
    return value.toTimeString ? value.toTimeString().substring(0, withSeconds ? 8 : 5) : '';
  }
}

export function getTime(time: null): null;
export function getTime(time: string | number, withSeconds?: boolean): { number: number; isValid: boolean };
export function getTime(time: string | number | Date | null, withSeconds?: boolean): null | { number: number; isValid: boolean };
export function getTime(time: string | number | Date | null, withSeconds = false): null | { number: number; isValid: boolean } {
  if (time instanceof Date && isNaN(time.getTime())) { time = null; }
  if (time === null) {
    return null;
  } else if (typeof time === 'number' || typeof time === 'string') {
    const today = new Date().toLocaleDateString();
    return {
      number: Number(String(time).slice(-10)),
      isValid: !isNaN(new Date(`${today} ${normalizeTimeStr(time, withSeconds)}`).getTime()),
    };
  } else {
    return {
      number: Number(time.toTimeString().substring(0, withSeconds ? 8 : 5).replace(/:/g, '')),
      isValid: true,
    };
  }
}
