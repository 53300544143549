import Base, { ITransmissionDoc} from '../base/transmission';
import { Amp } from '@hamstudy/flamp';
import { Block } from '@hamstudy/flamp/dist/block';
import { LTypes, ControlWord } from '@hamstudy/flamp/dist/amp';
import { TransmissionReceivedModel, TransmissionSentModel } from '@/data/models';


export class Transmission extends Base {
  static async getLastEntryTXTimestamp(eventId: string): Promise<Date> {
    return TransmissionSentModel.getLastestSentTimestamp(eventId);
  }

  static async getTxDoc(hash: string, eventId?: string): Promise<Transmission | null> {
    const doc = await TransmissionSentModel.findByHash(hash, eventId);
    return doc ? dbConverter.fromDB(doc, 'tx') : null;
  }
  static async getTxDocs(eventId: string): Promise<Transmission[]> {
    const docs = await TransmissionSentModel.getAllForEvent(eventId);
    return docs.map((doc) => dbConverter.fromDB(doc, 'tx'));
  }

  static async getRxDoc(hash: string, eventId?: string): Promise<Transmission | null> {
    const doc = await TransmissionReceivedModel.findByHash(hash, eventId);
    return doc ? dbConverter.fromDB(doc, 'rx') : null;
  }
  static async getRxDocs(eventId: string): Promise<Transmission[]> {
    const docs = await TransmissionReceivedModel.getAllForEvent(eventId);
    return docs.map((doc) => dbConverter.fromDB(doc, 'rx'));
  }


  static async delete(x: Transmission): Promise<void> {
    const model = x.txOrRx === 'tx' ? TransmissionSentModel : TransmissionReceivedModel;
    await model.deleteByHash(x.hash, x.eventId);
  }

  async save(): Promise<void> {
    const model = this.txOrRx === 'tx' ? TransmissionSentModel : TransmissionReceivedModel;
    const doc = dbConverter.toDB(this);
    await model.saveData(doc);
  }
}
export default Transmission;

// DB data converter
const dbConverter = {
  toDB(x: Transmission): ITransmissionDoc {
    let obj: ITransmissionDoc = {
      hash: x.hash,
      eventId: x.eventId,
      blocks: x.blocks,
      timestamp: x.timestamp,
    };
    if (x.entryCount) {
      obj.entryCount = x.entryCount;
    }
    if (x.hasAllChanges) {
      obj.hasAllChanges = true;
    }
    return obj;
  },
  fromDB(doc: ITransmissionDoc, txOrRx: 'tx' | 'rx') {
    const raceEventId = String(doc.eventId);
    const blocks: Amp['blocks'] = {};
    for (const key of Object.keys(doc.blocks) as Array<keyof Amp['blocks']>) {
      const b = doc.blocks[key as any];
      const keyword = b.keyword;
      const hash = b.hash;
      const data = b.data;
      switch(keyword) {
        case LTypes.DATA:
          blocks[key] = Block.MakeBlock({keyword, hash, data, blockNum: b.blockNum as number});
          break;
        case LTypes.CNTL:
          blocks[key] = Block.MakeBlock({keyword, hash, controlWord: b.controlWord as ControlWord});
          break;
        default:
          blocks[key] = Block.MakeBlock({keyword, hash, data});
      }
    }
    let x = new Transmission(raceEventId, {txOrRx, hash: doc.hash, blocks, entryCount: doc.entryCount});
    if (doc.hasAllChanges) { x.hasAllChanges = doc.hasAllChanges; }
    x.timestamp = new Date(doc.timestamp);
    return x;
  },
};
