import Base, { IEventDoc } from '../base/event';
import ObjectId from '@/data/models/ObjectId';
// import { collection as participantCollection } from './participant';
// import { collection as stationCollection } from './station';
// import { AmpFileCol } from '../transmission';
import { EntryModel, EventModel, ParticipantModel, RaceModel, StationModel, TransmissionReceivedModel, TransmissionSentModel } from '@/data/models';

export class Event extends Base {
  static async getEvent(idOrSlug: string): Promise<Event | null> {
    if (!idOrSlug) { return null; }
    const doc = await EventModel.findByIdOrSlug(idOrSlug);
    return doc ? dbConverter.fromDB(doc) : null;
  }
  static async getEvents(): Promise<Event[]> {
    const docs = await EventModel.listEvents();
    return docs.map((doc) => dbConverter.fromDB(doc));
  }
  static async delete(event: Event) {
    await Promise.all([
      EntryModel.deleteAllForEvent(event.id),
      ParticipantModel.deleteAllForEvent(event.id),
      StationModel.deleteAllForEvent(event.id),
      RaceModel.deleteAllForEvent(event.id),
      TransmissionReceivedModel.deleteAllForEvent(event.id),
      TransmissionSentModel.deleteAllForEvent(event.id),
      EventModel.deleteById(event.id),
    ]);
  }

  async save() {
    await EventModel.saveData(dbConverter.toDB(this));
  }
}
export default Event;

// DB data converter
const dbConverter = {
  toDB(x: Event) {
    x.id = x.id || new ObjectId().toString();
    const doc: IEventDoc = {
      id: x.id,
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
      slug: x.slug,
    };
    return doc;
  },
  fromDB(doc: IEventDoc) {
    const startDate = doc.startDate ? new Date(doc.startDate) : (doc as any).date ? new Date((doc as any).date) : (void 0);
    const endDate = doc.endDate ? new Date(doc.endDate) : (void 0);
    return new Event({
      ...Object.assign({}, doc),
      startDate,
      endDate,
    });
  },
};
