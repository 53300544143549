import { App } from '@capacitor/app';
import { Device, type DeviceInfo } from '@capacitor/device';
import { defineStore } from '../plugins/pinia';
import { ref } from 'vue';

export const useAppStore = defineStore('app', () => {
  const appVersion = ref(process.env.VERSION);
  const deviceInfo = ref<DeviceInfo | null>(null);

  (async () => {
    try {
      const appInfo = await App.getInfo();
      appVersion.value = appInfo.version;
    } catch (e: any) {
      if (e.message !== 'Not implemented on web.') {
        console.error('Unable to get app info', e);
      }
    }
  })();
  (async () => {
    try {
      deviceInfo.value = await Device.getInfo();
    } catch (e: any) {
      if (e.message !== 'Not implemented on web.') {
        console.error('Unable to get device info', e);
      }
    }
  })();

  const isTouchDevice = ref(false);
  document.addEventListener('pointerdown', (pointerEvent) => {
    if (pointerEvent.pointerType === 'mouse') {
      isTouchDevice.value = false;
    } else {
      isTouchDevice.value = true;
    }
  });

  return {
    appVersion,
    deviceInfo,
    isTouchDevice,
  };
});
