import Base, { EntryQuery, IEntryDoc} from '../base/entry';
import ObjectId from '@/data/models/ObjectId';
import { EntryModel } from '@/data/models';

export class Entry extends Base {
  static async getEntry(id: string): Promise<Entry | null>;
  static async getEntry(stationId: string, participantId: string): Promise<Entry | null>; // tslint:disable-line unified-signatures
  static async getEntry(id: string, participantId?: string): Promise<Entry | null> {
    let doc: IEntryDoc | undefined;
    if (!participantId) {
      doc = await EntryModel.findById(id);
    } else {
      doc = await EntryModel.findByStationAndParticipant(id, participantId) || undefined;
    }
    return doc ? dbConverter.fromDB(doc) : null;
  }
  static async getEntrys({participantId, stationId, eventId, sinceDate}: EntryQuery) {
    let docs: IEntryDoc[] = [];
    if (participantId) {
      docs = await EntryModel.getEntrysForParticipant(participantId);
    } else if (stationId) {
      docs = await EntryModel.getEntrysForStation(stationId, sinceDate);
    } else if (eventId) {
      docs = await EntryModel.getEntrysForEvent(eventId);
    }
    return docs.map(dbConverter.fromDB);
  }

  static async delete(entry: Entry) {
    await EntryModel.deleteById(entry.id);
  }

  async save() {
    await EntryModel.saveData(dbConverter.toDB(this));
  }
}
export default Entry;

// DB data converter
const dbConverter = {
  toDB(x: Entry) {
    x.id = x.id || new ObjectId().toString();
    x.modified = new Date();
    return x;
  },
  fromDB(doc: IEntryDoc) {
    const raceEventId = String(doc.eventId);
    const participantId = String(doc.participantId);
    const stationId = String(doc.stationId);
    return new Entry(raceEventId, participantId, stationId, Object.assign({}, doc));
  },
};
