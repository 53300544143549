export const dbName = <const>'entrys';

export interface EntryQuery {
  participantId?: string;
  stationId?: string;
  eventId?: string;
  sinceDate?: Date;
}
// tslint:disable max-classes-per-file
abstract class EntryDoc {
  id!: string;
  eventId!: string;
  participantId!: string;
  stationId!: string;

  modified!: Date;
  timeIn: Date | null = null;
  timeOut: Date | null = null;
}
export type IEntryDoc = EntryDoc;
export class Entry extends EntryDoc {
  static async getEntry(id: string): Promise<Entry | null>;
  static async getEntry(stationId: string, participantId: string): Promise<Entry | null>; // tslint:disable-line unified-signatures
  static async getEntry(id: string, participantId?: string): Promise<Entry | null> { throw new Error("Can't use base model"); }
  static async getEntrys({participantId, stationId, eventId, sinceDate}: EntryQuery): Promise<Entry[]> { throw new Error("Can't use base model"); }
  static async delete(entry: Entry): Promise<void> { return; }

  static async importEntry(eventId: string, stationId: string, participantId: string, entryData: Partial<IEntryDoc>) {
    const entry = (await this.getEntry(stationId, participantId)) || new this(eventId, participantId, stationId);
    if (entryData.timeIn === null) {
      entry.timeIn = null;
    } else if (entryData.timeIn && Date.parse(String(entryData.timeIn))) {
      entry.timeIn = new Date(entryData.timeIn);
    }
    if (entryData.timeOut === null) {
      entry.timeOut = null;
    } else if (entryData.timeOut && Date.parse(String(entryData.timeOut))) {
      entry.timeOut = new Date(entryData.timeOut);
    }
    try {
      await entry.save();
    } catch (e) {
      console.error(e); // tslint:disable-line no-console
      throw e;
    }
    return entry;
  }

  constructor(
    eventId: string,
    participantId: string,
    stationId: string,
    obj: Partial<Entry> = {},
  ) {
    super();
    Object.assign(this, obj, {eventId, participantId, stationId});
    if (!this.id && !this.modified) { this.modified = new Date(); }
  }

  async save(): Promise<void> { return; }
}
export default Entry;
