import type { StoreNames, StoreType } from ".";
import { idbModel } from "./idbModel";
import type { TransmissionSchema } from "./transmissionSent";

export class TransmissionReceivedModel extends idbModel<TransmissionSchema> {
  static readonly StoreName = 'transmissionReceived';
  static readonly StoreSchema = {
    key: TransmissionReceivedModel.StoreName,
    value: null as any as TransmissionSchema,
    indexes: {
      'eventId': 'eventId',
      'stationId': 'stationId',
      'event-hash': ['eventId','hash'],
      'event-timestamp': ['eventId','timestamp'],
    },
    keyPath: 'hash',
    indexOptions: {
      'event-hash': { unique: true },
    },
  };


  static async findByHash(hash: string, eventId?: string): Promise<TransmissionSchema | undefined> {
    const store = await this.getObjectStore<'transmissionReceived'>("readonly");
    const range = eventId ? IDBKeyRange.only([eventId, hash]) : IDBKeyRange.only(hash);
    return await store.index('event-hash').get(range);
  }

  static async getAllForEvent(eventId: string): Promise<TransmissionSchema[]> {
    const store = await this.getObjectStore<'transmissionReceived'>("readonly");

    const range = IDBKeyRange.only(eventId);

    const outDocs: TransmissionSchema[] = [];

    const iterate = store.index('eventId').iterate(range);
    for await (const doc of iterate) {
      outDocs.push(doc.value);
    }
    return outDocs;
  }

  static async deleteByHash(hash: string, eventId: string): Promise<void> {
    const store = await this.getObjectStore<'transmissionReceived'>("readwrite");
    const range = IDBKeyRange.only([eventId, hash]);
    for await (const r of store.index('event-hash').iterate(range)) {
      await r.delete!();
    }
  }

  static async deleteAllForEvent(eventId: string): Promise<void> {
    const store = await this.getObjectStore<'transmissionReceived'>('readwrite', 'strict');

    const range = IDBKeyRange.only(eventId);

    const operations: Array<Promise<any>> = [];
    for await (const r of store.index('eventId').iterate(range)) {
      operations.push(r.delete!());
    }
    await Promise.all([
      ...operations,
      store.transaction.done,
    ]);
  }
}
