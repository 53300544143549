import { Capacitor } from '@capacitor/core';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'typeface-roboto-mono';
import { Compressor } from '@hamstudy/flamp/dist/compressor';
import VueI18next from '@panter/vue-i18next';
import Vue from 'vue';
import './main.scss';

import Unsupported from './Unsupported.vue';
import { pinia } from '@/plugins/pinia';
import router from './router'; // import after the store
import { i18next } from './locales/i18next';
import { MT63Client, readyDfd as wasmReady } from '@/lib/mt63';
import vuetify from '@/plugins/vuetify';
import '@/plugins/timeFilter';
import { Event } from '@/models/event';
import { Station } from '@/models/station';


// This should be imported last as it requires the store and maybe the router etc
import App from './App.vue';
import { useRaceEventStore } from './stores/raceEvent';

Vue.config.productionTip = false;
i18next.init();
Vue.use(VueI18next);


Promise.all([
  wasmReady,
]).then(() => {
  let mtClient = new MT63Client();
  Compressor.addCompressor({
    prefix: "\u0001LZMA",
    compress: mtClient.lzmaEncode,
    decompress: mtClient.lzmaDecode,
  });
});
const wasmSupported = (() => {
    try {
      if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
        const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
        if (module instanceof WebAssembly.Module) {
          return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
        }
      }
    } catch (e) { } // tslint:disable-line no-empty
    return false;
})();
if (!wasmSupported) {
  renderUnsupported();
} else {
  (async () => {
    try {
      const raceEventStore = useRaceEventStore();
      const { route } = router.resolve(window.location.pathname);

      const eventIdOrSlug = route?.params.eventIdOrSlug || raceEventStore.eventId;
      const event = await Event.getEvent(eventIdOrSlug);
      raceEventStore.eventId = event?.id || '';
      raceEventStore.eventSlug = event?.slug || '';

      if (event) {
        const stationId = route?.params.stationIdOrSlug || raceEventStore.stationId;
        const station = await Station.getStation({stationId, eventId: event.id});
        raceEventStore.station = station;
        raceEventStore.stationId = station?.id || '';
      }
      new Vue({
        i18n: new VueI18next(i18next),
        router,
        pinia,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    } catch (e: any) {
      try {
        const settingsStore = useRaceEventStore();
        settingsStore.eventId = '';
        settingsStore.eventSlug = '';
        settingsStore.stationId = '';
        settingsStore.station = null;
      } catch {} // tslint:disable-line no-empty
      console.error(e); // tslint:disable-line no-console
      renderUnsupported(e);
    }
  })();
}

function renderUnsupported(reason: string = '') {
  console.log('Unable to load app', reason); // tslint:disable-line no-console
  new Vue({
    i18n: new VueI18next(i18next),
    render: (h) => h(Unsupported, { props: {
      reason,
    }}),
  }).$mount('#app');
}


